import React, { useState, useEffect } from "react"

import { TextField,Button,Grid,Box ,FormGroup,FormControlLabel,Switch , MenuItem , Select,InputLabel,
        Alert , Table ,TableBody ,TableCell ,TableContainer ,TableHead , TableRow,Paper,Divider} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { doc,getDoc,setDoc ,collection,getDocs,query,orderBy,limit} from "firebase/firestore";
import {db} from '../../utils/firebase.js'
import { conventDateTime } from "../../utils/util.js";
import { useNavigate ,useLocation} from "react-router-dom";
const Driver = () => {
  const nav = useNavigate()
  const theme = createTheme();
  const location = useLocation();
  const [driver, setDriver] = useState({})
  const [trips , setTrips]=useState([])
  const [alert, setAlert] = useState ({severity:"info",show:false , info:""})
  useEffect(()=>{
   
      const driverSnap = doc(db,"drivers" , location.state.id)

      getDoc(driverSnap).then((d)=>{
        
        setDriver({...d.data() , id: d.id})
      })

      const getTripsData = async()=>{
        const tripsSnap = getDocs(query(collection(db,"drivers" ,location.state.id , "trips" ) , limit(50) , orderBy("creation_time" , "desc")))
        setTrips((await tripsSnap).docs.map((doc)=>({...doc.data() , id: doc.id})))
      }
      getTripsData()
  },[])

  const handleChange = (event)=>{
    let previousData = { ...driver };
    // console.log(event.target.type)
    if (event.target.type === "checkbox") {
      previousData[event.target.name] = event.target.checked
    }else{
      previousData[event.target.name] = event.target.value
    }
   

    setDriver(previousData)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const driverRef = doc(db,"drivers" , location.state.id)
    setDoc(driverRef , driver , {merge:true}).then(doc =>{
      setAlert({severity:"info", show:true ,  info:"update successfully" })
    }).catch(err =>{
      setAlert({severity:"error", show:true ,  info:"Update error" })
      console.err(err)
    })
    console.log(driver);


  };




  const renderTrips=(row)=>{
    return (
      <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } ,
                                      '&.MuiTableRow-hover':{cursor: "pointer" }
                                }}
                                hover ={true}
                                onClick={()=>  nav(`/trip/${row.id}` ,{state:{id:row.id}}) }>
        <TableCell>{row.license_plate}</TableCell>
        
          <TableCell>{conventDateTime( row.trip_start)}</TableCell> 
          <TableCell>{conventDateTime( row.trip_end)}</TableCell> 
          <TableCell>{ row.payment_type}</TableCell> 
          <TableCell align="right" >{ row.fare}</TableCell> 
      </TableRow>
    )
  }

  return (
    <ThemeProvider theme={theme}>
    { alert.show &&
      <Alert severity="info">
        {alert.info}
      </Alert>
    }

    <div className="admin-right">
   
      <h2>Driver {location.state.id}</h2>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField required fullWidth name="name" label="Name" value={driver.name || '' } onChange={handleChange}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField required fullWidth  name="name_ch" label="Chinese Name" value={driver.name_ch || ''} onChange={handleChange}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField required fullWidth  name="phone_number" label="Phone" value={driver.phone_number || '' } onChange={handleChange}/>
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormGroup>
              <FormControlLabel control={<Switch checked={driver.is_admin || false } name="is_admin" onChange={handleChange}/>} label="Admin" />
            </FormGroup>
          </Grid>
          <Grid item xs={3} sm={3}>
          <InputLabel id="gender-label">Gender</InputLabel>
            <Select labelId="gender-label" id="gender-select" value={driver.gender ||'' } label="Gender" name="gender" onChange={handleChange} >
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField required fullWidth  name="hkid" label="HK ID" value={driver.hkid || ''} onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField  fullWidth  name="driver_license" label="Driver License" value={driver.driver_license || ''} onChange={handleChange}/>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField  fullWidth  name="bank_account" label="Bank Account" value={driver.bank_account || ''} onChange={handleChange}/>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField  fullWidth  name="bank_account_owner_name" label="Bank Account Owner Name" value={driver.bank_account_owner_name || ''} onChange={handleChange}/>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField  fullWidth  name="bank_id" label="Bank Id" value={driver.bank_id || ''} onChange={handleChange}/>
          </Grid>
          <Grid item xs={12} sm={12}>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Update</Button>
          </Grid>
          <Divider />
          <Grid item xs={12} sm={12}>
            {trips && 
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="Trip table">
                <TableHead>
                  <TableRow>
                    <TableCell>License Plate</TableCell>
                    <TableCell>Trip Start</TableCell>
                    <TableCell>Trip End</TableCell>
                    <TableCell>Payment Type</TableCell>
                    <TableCell>Fare</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {trips.map((data) => (
                  renderTrips(data)
                ))}
                </TableBody>
              </Table>
              </TableContainer>
            }
          </Grid>
        </Grid>
       
      </Box>
      </div>
     
    </ThemeProvider>
  )
}

export default Driver