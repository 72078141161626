import React, { useState , useEffect } from "react"

import { collection,getDocs,query, orderBy, limit  ,getCountFromServer , where,startAfter ,endBefore,limitToLast } from "firebase/firestore"
import {db} from '../../utils/firebase.js'
import {Table,TableBody,TableRow,TablePagination,TableHead,TableContainer,TableCell ,
        Box,TextField, CircularProgress,Grid, FormControl,InputLabel,Paper , Collapse,
        IconButton, Button,Backdrop, AppBar,Typography, Toolbar,  MenuItem,  Select ,Checkbox, 
        Snackbar, Alert, AlertTitle, ButtonGroup} from '@mui/material';

import { useNavigate  } from "react-router-dom";
import { conventDateTime } from "../../utils/util.js";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {   RestartAlt, Search,Close } from "@mui/icons-material"
import {NumericFormat } from 'react-number-format';
import Api from "../../utils/api";


const SearchByLabel ={
  id : "Id",
  meter_id:"Meter",
  trip_start:"Trip start",
  "driver.id":"Driver ID",
  payout_status:"Status"
}

const TripsList =()=>{
  const api=new Api()
  const [trips, setTrips] = useState([])
  const [loading ,setLoading] = useState(true)
  const navigate = useNavigate()
  const [expanded,setExpanded] =useState(false)
  const [pagination,setPagination] = useState({
    first : null,
    last : null,
    page: 0,
    rowsPerPage :25 , 
    count: 0
  })
  
  
  const [searchForm , setSearchForm] =useState({
    fieldName:'',
    fieldValue:'',
    tripStartFrom:null,
    tripStartTo:null,
  })

  const [selectedRow , setSelectedRow] = useState([])

  
  

  const  [constraints , setConstraints] = useState([])
  const [checkBoxEnable , setCheckBoxEnable] = useState(false)
  const [alert , setAlert] =useState({  severity:"info",show:false , title:"" , message:""})

  const handleChangePage = (event, newPage) => {
    //  console.log("c2" , constraints , pagination)
     const clone_con =  constraints.filter(con => con.type !== 'startAfter' && con.type !== 'endBefore' && con.type !== 'limit' && con.type !== 'limitToLast') 
    if(newPage > pagination.page){  //next page

      clone_con.push(startAfter(pagination.last))
      clone_con.push(limit(pagination.rowsPerPage))
    }else{
      clone_con.push(endBefore(pagination.first))
      clone_con.push(limitToLast(pagination.rowsPerPage))
    }
    setConstraints(clone_con)
    setPagination({...pagination , page:newPage})
  };



  const handleChangeRowsPerPage = (event) => {
    setPagination({...pagination , rowsPerPage:parseInt(event.target.value, 10) , page:0 , first:null, last:null , count:0})
    const clone_con =  constraints.filter(con => con.type !== 'startAfter' && con.type !== 'endBefore' && con.type !== 'limit' && con.type !== 'limitToLast') 
    clone_con.push(limit(parseInt(event.target.value, 10)))
    setConstraints(clone_con)
    
  };



  useEffect(()=>{
    if(constraints.length >0){
      setSelectedRow([])
      getData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [constraints])

  const getData = async() =>{
    
    setLoading(true)
    const tripsRef= collection(db,"trips")

    if(pagination.count===0 ){
      var clone_con = constraints.filter(con => con.type !== 'limit')
      const cnt_snapshot =await  getCountFromServer(query(tripsRef , ...clone_con))
      setPagination({...pagination , count:cnt_snapshot.data().count })
      
    }

    const q=  query(tripsRef , ...constraints)
    const tripsSnap =  getDocs(q)
    setTrips((await tripsSnap).docs.map((doc) => ({...doc.data() , id : doc.id})))
    
  }

  useEffect(()=>{
    const last =trips.length > 0 ? trips[trips.length-1].creation_time : null;
    const first = trips.length > 0 ? trips[0].creation_time : null;
    setPagination({...pagination , last: last  ,first : first })

    setLoading(false)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [trips])

  useEffect(()=>{    
    // console.log("startup")
     clickReset()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const clickRow = (row) =>{
    navigate(`/trip/${row.id}` ,{state:{id:row.id}})
   
  }

  const checkRow = (id ) =>{
    const selected = [...selectedRow]
    if (!selectedRow.includes(id)){
      selected.push(id)
    }else {
      selected = selectedRow.filter(v => v !== id )
    }
    setSelectedRow(selected)
  }

  const checkAll = ()=>{
    const selected =[]
    if(selectedRow.length ===0  && selectedRow < trips.length){
      trips.forEach(data => selected.push(data.id) )
    }
    setSelectedRow(selected)
  }

  const renderData =(row)=>{
    return (
      
      <TableRow
        key={row.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } ,
                '&.MuiTableRow-hover':{cursor: "pointer"}
        }}
        onClick={()=> clickRow(row)}
        hover ={true}
        >
          <TableCell onClick={(e) => e.stopPropagation()}><Checkbox checked={selectedRow.includes(row.id)} onClick={() => checkRow(row.id)} disabled={!checkBoxEnable}/></TableCell>
          <TableCell>{row.license_plate}</TableCell>
          <TableCell>{row.driver.id}</TableCell>
          <TableCell>{conventDateTime( row.trip_start)}</TableCell> 
          <TableCell>{conventDateTime( row.trip_end)}</TableCell> 
          <TableCell>{ row.payment_type}</TableCell> 
          <TableCell >{ row.payout_status}</TableCell> 
          <TableCell align="right"><NumericFormat value={row.fare} decimalScale={1} displayType="text"  prefix={'$'} thousandSeparator="," /></TableCell> 
          <TableCell align="right"><NumericFormat value={row.dash_fee} decimalScale={1} displayType="text"  prefix={'$'} thousandSeparator="," /></TableCell> 
          <TableCell align="right"><NumericFormat value={row.extra} decimalScale={1} displayType="text"  prefix={'$'} thousandSeparator="," /></TableCell> 
          <TableCell align="right" ><NumericFormat value={row.dash_tips } decimalScale={1} displayType="text"  prefix={'$'} thousandSeparator="," /></TableCell> 
          <TableCell align="right" sx={{pr: 2}}><NumericFormat value={row.total} decimalScale={1} displayType="text"  prefix={'$'} thousandSeparator="," /></TableCell>
          
      </TableRow>
    )
  }

  const  handleSearchFormChange = (event)=>{
    setSearchForm( { ...searchForm  , fieldValue:  event.target.value })
  }


  const clickReset = ()=>{
    // constraints.splice(0, constraints.length);
    const con = [where('creation_time', '!=', null) ,orderBy("creation_time","desc")    , limit(pagination.rowsPerPage )]
    setConstraints(con)
    setPagination({...pagination , count:0 , first:null , last:null , page:0} )
    setCheckBoxEnable(false)
  } 


  const clickSearch=(event)=>{
    event.preventDefault();
    const con = []
    if(searchForm.fieldName ==='trip_start'){
      
      if(searchForm.tripStartFrom){
        con.push(where(searchForm.fieldName , '>=' ,searchForm.tripStartFrom.toDate() ))
      }
      if(searchForm.tripStartFrom){
        con.push(where(searchForm.fieldName , '<=' ,searchForm.tripStartTo.toDate() ))
      }
      con.push(limit(pagination.rowsPerPage ) )
      con.push(orderBy("trip_start","desc" ))
    
    }else if(searchForm.fieldName === 'id'){
      con.push(where(searchForm.fieldName ,  '==' ,searchForm.fieldValue  ))
    }else {
      con.push(where(searchForm.fieldName ,  '==' ,searchForm.fieldValue  ))
      con.push(limit(pagination.rowsPerPage ))
      con.push(orderBy("creation_time","desc" ))
    }
    setConstraints(con)
    setPagination({...pagination , count:0 , first:null , last:null , page:0} )
    setSelectedRow([])
    setCheckBoxEnable(false)


  }

  const filterRelease = () =>{
    // console.log("filterRelease")
    // constraints.splice(0, constraints.length);
    const con = [where("payout_status" ,"==" , 'NONE' ) , where("payment_type" ,"==" , "DASH" ) , orderBy("driver.id" ) , orderBy("creation_time" , "desc")]
    setConstraints(con)
    setPagination({...pagination , count:0 , first:null , last:null , page:0} )
    setCheckBoxEnable(true)
  } 

  const preRelease =()=>{
    


    if(selectedRow.length >0){
      const payload =[]
      selectedRow.forEach((v)=> {
        const trip =trips.find(t => t.id === v)
        let driverMap = payload.find(dm => dm.driver_id === trip.driver.id)
        if(driverMap){
          driverMap.trips.push(v)
        }else{
          driverMap = {driver_id:trip.driver.id , trips: [v] }
          payload.push(driverMap)
        }
        
        
      })
      // console.log("payload",payload)

      setLoading(true)
      api.fundRelease(payload).then(resp =>{
        
        console.log(resp)
        // var anyFail = resp.data.filter(v => v.result ==="FAILED")
         const ale = {  severity:"info",show:true , title:"Update success"  , message:"update PreRelese success"}

        setAlert(ale)
        clickReset()

      })
      .catch(err=>{
        console.log("err", err)
        const ale = {  severity:"error",show:true , title:"System Error"  , message:err.response.headers}
        setAlert(ale)
        
      })
      .finally(()=>{
        setLoading(false)
      })
    }
  }

return (
  <div style={{margin:8}}>

  <AppBar position="static" color="transparent">
    
    <Toolbar>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Trips</Typography>
      <ButtonGroup variant="outlined" size="small">
        <Button  onClick={ ()=> setExpanded(!expanded)}>Search</Button>
        <Button onClick={filterRelease}>Find Release</Button>
        <Button onClick={preRelease} disabled={selectedRow.length ===0} >PreRelese</Button>
      </ButtonGroup>
    </Toolbar>
  </AppBar>
    
  <Collapse in={expanded} timeout="auto" unmountOnExit>
    <Box component="fieldset" sx={{ p: 2, borderColor: 'grey.500' }} noValidate autoComplete="off" borderRadius={2}>
      <legend><Typography margin={1} >Search</Typography></legend>
      <Grid container spacing={2}>
        <Grid item xs={3}>
        <FormControl  variant="standard"  fullWidth>
          <InputLabel >Field</InputLabel>
          <Select value={searchForm.fieldName || ''}
                  onChange={(event) => setSearchForm( { ...searchForm  , fieldName:  event.target.value , fieldValue:null ,tripStartFrom:null,tripStartTo:null })  }>
              { Object.keys(SearchByLabel).map( (key,idx) =>{
                return (<MenuItem key={`mitem-${key}`} value={key}>{SearchByLabel[key]}</MenuItem>)
              }) }
          </Select>
        </FormControl>
        </Grid>
        {
          (searchForm.fieldName === 'trip_start' && 
            <LocalizationProvider Grid container dateAdapter={AdapterMoment}>
            <DateTimePicker  renderInput={(props) => 
              <Grid item xs={3}>
                <TextField  name="tripStartFrom" {...props} variant="standard" fullWidth />
              </Grid>
            }
            label="Trip start from" value={searchForm.tripStartFrom} inputFormat="DD/MM/YYYY HH:mm" 
            onChange={(newValue)=> {
              setSearchForm({...searchForm ,tripStartFrom: newValue })
            }} />

            <DateTimePicker  renderInput={(props) =>
              <Grid item xs={3}>
                <TextField  name="tripStartTo" {...props} variant="standard" fullWidth/>
              </Grid>
              }
              label="Trip start to" value={searchForm.tripStartTo} inputFormat="DD/MM/YYYY HH:mm"  
              onChange={(newValue)=> {
                setSearchForm({...searchForm ,tripStartTo: newValue })
              }}/>
            </LocalizationProvider>
          )
          || (searchForm.fieldName ==='payout_status' &&
              <Grid item xs={6}>
                <FormControl   variant="standard" fullWidth>
                  <InputLabel   htmlFor="uncontrolled-native">Status</InputLabel>
                  <Select value={searchForm.fieldValue || ''} onChange={handleSearchFormChange} >
                    <MenuItem value="NONE">NONE</MenuItem>
                    <MenuItem value="PRERELEASE">PRERELEASE</MenuItem>
                    <MenuItem value="RELEASED">RELEASED</MenuItem>
                  </Select>
                  </FormControl>
              </Grid>
          )
        || (searchForm.fieldName   &&
            <Grid item xs={6}><TextField  label={SearchByLabel[searchForm.fieldName]} variant="standard" fullWidth name={searchForm.fieldName} value={searchForm.fieldValue || ''} onChange={handleSearchFormChange}/> </Grid>
          )
        }
        <Grid item xs={3}>
          <Button sx={{mt:1}} variant="outlined" endIcon={<Search/>} onClick={clickSearch}>Search</Button>
          <Button sx={{mt:1,ml:1}} variant="outlined" endIcon={<RestartAlt/>} onClick={clickReset}>Reset</Button>
        </Grid>
      </Grid>
      </Box>
      </Collapse>



    {loading ? "loading" : 
    <TableContainer component={Paper} >
      <Table aria-label="Driver table" size="small" padding="none">
        <TableHead>
          <TableRow >
            <TableCell><Checkbox 
                        color="primary" 
                        checked={trips.length>0 && selectedRow.length === trips.length }
                        indeterminate={selectedRow.length > 0 && selectedRow.length < trips.length}
                        onClick={checkAll}
                        disabled={!checkBoxEnable}
                        />
            </TableCell>
            <TableCell >License Plate </TableCell>
            <TableCell>Driver</TableCell>
            <TableCell>Trip Start</TableCell>
            <TableCell>Trip End</TableCell>
            <TableCell>Payment Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Fare</TableCell>
            <TableCell align="right">Dash Fee</TableCell>
            <TableCell align="right">Extra</TableCell>
            <TableCell align="right">Tips</TableCell>
            <TableCell align="right" sx={{pr: 2}}>Total</TableCell>
            
          </TableRow>
        </TableHead>
     <TableBody>
     {trips.map(renderData)}
     </TableBody>
     </Table>
    </TableContainer>

  }
        <TablePagination
         rowsPerPageOptions={[5, 10, 25,50]}
         component="div"
         count={pagination.count}
         rowsPerPage={pagination.rowsPerPage}
         page={pagination.page}
         onPageChange={handleChangePage}
         onRowsPerPageChange={handleChangeRowsPerPage}
         
       />
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading} >
        <CircularProgress color="inherit" />
    </Backdrop>
    <Snackbar open={alert.show} sx={{width: '95%' }}>
      <Alert severity={alert.severity} sx={{width: '100%' }} 
        action={
          <IconButton aria-label="close"
            color="inherit" size="small"
            onClick={()=>{
              setAlert({...alert , show:false})
            }} >
              <Close fontSize="inherit"/>
            </IconButton>
          }
        >
        <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}</Alert>
    </Snackbar>
  </div>
  
)
}

export default TripsList