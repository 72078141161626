import React from 'react';
import './App.css';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import PrivateRoutes from './utils/PrivateRoutes';
import { Routes, Route, BrowserRouter as Router , Navigate} from 'react-router-dom';
import Logout from './components/Logout';
import DriversList from './components/driver/drivers-list';
import MetersList from './components/meter/meters-list';
import TripsList from './components/trip/trips-list';
import Driver from './components/driver/driver';
import Trip from './components/trip/trip';
import Meter from './components/meter/meter';

import DevicesList from './components/device/devices-list'
import BatchesList from './components/batch/batches-list';


const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<PrivateRoutes/>}>
            <Route element={<Dashboard/>} path="/dashboard" exact/>
            <Route element={<DriversList/>} path="/drivers" exact/>
            <Route element={<Driver/>} path="/driver/:id" exact/>
            <Route element={<MetersList/>} path="/meters" exact/>
            <Route element={<Meter/>} path="meter/:id" exact />
            <Route element={<TripsList/>} path="/trips" exact/>
            <Route element={<Logout/>} path="/logout" exact/>
            <Route element={<Trip/>} path="/trip/:id" exact/>
            <Route element={<DevicesList/>} path="/devices" exact/>
            <Route element={<BatchesList/>} path="/batches" exact/>
          </Route>
          <Route element={<Login/>} path="/login" />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Router>
 
    </div>
  );
}

export default App;
