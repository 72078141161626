import React from "react"
import { Outlet,Navigate } from "react-router-dom";
import SideNav from "../components/side-nav/side-nav";

const PrivateRoutes =()=>{
  let token= localStorage.getItem("token")
  let auth ={'token':token ? true : false}
  
  
  return(

      auth.token ? 
      <div className="container">
        <SideNav/>
        <main>
        <Outlet/>
        </main>
      </div>
      :
      <Navigate to="/login"/>
    
  )
}

export default PrivateRoutes