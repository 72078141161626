import React from "react"
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SpeedIcon from '@mui/icons-material/Speed';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';

import {  AutoAwesomeMotion, Smartphone ,Logout} from "@mui/icons-material";

export const navData=[
  {
    id:0,
    icon: <HomeIcon/> ,
    text :"Dashboard" , 
    link: "/dashboard" , 
  },
  {id: 1 ,
    icon: <ManageAccountsIcon/> , 
    text: "Drivers",
    link:"/drivers"
  },
  {id: 2 ,
    icon: <SpeedIcon/> , 
    text: "Meters",
    link:"/meters"
  },
  {
    id: 3,
    icon: <Smartphone/>,
    text: "Device",
    link:"/devices"

  },
  {id: 4 ,
    icon: <ModeOfTravelIcon/> , 
    text: "Trips",
    link:"/trips"
  },
  {
    id:5,
    icon: <AutoAwesomeMotion/>,
    text: "Batch",
    link: "/batches"
  },
  {id: 6 ,
    icon: <Logout/> , 
    text: "Logout",
    link:"/logout"
  }



]